/*订单接口*/
import {postAjaxRequest} from '@/utils/request'
import {yqfApiurl,yqfComApiurl} from '@/utils/webconfig'

// export var apiurl = "https://3gapitest.yiqifei.com";//测试环境
// export var apiurl = "http://3gapilocal.yiqifei.com"; //本地环境

//检查旅客信息
export function checkPassenger(parm, callback){
	console.log('checkPassenger');
	postAjaxRequest('/Booking/CheckPassenger', parm, function(result) {
		callback(result);
	})
}
//提交订单
export function submitOrder(parm, callback) {
	console.log('submitOrder');
	postAjaxRequest('/Booking/SubmitOrder', parm, function(result) {
		callback(result);
	})	
}
//获取订单列表
export function getOrderList(parm, callback) {
	console.log('getOrderList');
	postAjaxRequest('/OrderAPI/GetOrderList', parm, function(result) {
		callback(result);
	})	
}
//获取订单详情
export function getOrderDetailByID(parm, callback) {
	console.log('getOrderDetailByID');
	postAjaxRequest('/OrderAPI/GetOrderDetailByID', parm, function(result) {
		callback(result);
	})	
}
//获取订单的旅行顾问
export function getTravelAdvisoryFollow(parm, callback) {
	console.log('getTravelAdvisoryFollow');
	postAjaxRequest('/OrderAPI/GetTravelAdvisoryFollow', parm, function(result) {
		callback(result);
	})	
}
//退票
export function geRefundTicket(parm, callback) {
	console.log('geRefundTicket');
	postAjaxRequest('/OrderAPI/GeRefundTicket', parm, function(result) {
		callback(result);
	})	
}
//查询退票费用
export function GeRefundTicketFare(parm, callback) {
	console.log('GeRefundTicketFare');
	postAjaxRequest('/OrderAPI/GeRefundTicketFare', parm, function(result) {
		callback(result);
	})	
}
//改签
export function GetChange(parm, callback) {
	console.log('GetChange');
	postAjaxRequest('/OrderAPI/GetChange', parm, function(result) {
		callback(result);
	})	
}
//出票
export function GetFlowPSOCustomerDocStatusUpdate(parm, callback) {
	console.log('GetFlowPSOCustomerDocStatusUpdate');
	postAjaxRequest('/OrderAPI/GetFlowPSOCustomerDocStatusUpdate', parm, function(result) {
		callback(result);
	})	
}
//取消订单
export function GetLRSalesOrderCancel(parm, callback) {
	console.log('GetLRSalesOrderCancel');
	postAjaxRequest('/OrderAPI/GetLRSalesOrderCancel', parm, function(result) {
		callback(result);
	})	
}
//取消PNR
export function GetCancelPNR(parm, callback) {
	console.log('GetCancelPNR');
	postAjaxRequest('/OrderAPI/GetCancelPNR', parm, function(result) {
		callback(result);
	})	
}
//取消旅客PNR
export function GetCancelPNRPassenager(parm, callback) {
	console.log('GetCancelPNRPassenager');
	postAjaxRequest('/OrderAPI/GetCancelPNRPassenager', parm, function(result) {
		callback(result);
	})	
}
//添加旅客PNR手机号
export function GetAddPNRPassenagerMobile(parm, callback) {
	console.log('GetAddPNRPassenagerMobile');
	postAjaxRequest('/OrderAPI/GetAddPNRPassenagerMobile', parm, function(result) {
		callback(result);
	})	
}
